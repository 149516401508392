import * as React from 'react';
import {
    TextField,
    RichTextField,
    Tab,
    useTranslate,
    FunctionField,
    useLocale,
} from 'react-admin';
import { useHistory   } from 'react-router';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import ShareIcon from '@material-ui/icons/Share';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MaterialTextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import 'moment/locale/de';
//
// Fetches and Shows information about Main Heading
//

const InformationTab = (props) => {
    const translate = useTranslate()
    const mesh_id = props.record.descriptor_id;
    const nlmURL = `https://meshb.nlm.nih.gov/record/ui?ui=${mesh_id}`
    const [anchorEl, setAnchorEl] = React.useState(null);
    const locale = useLocale();
    const routerHistory = useHistory();
    const [shareURL, setShareURL] = React.useState((props.urlState || "Placeholder"))
    console.log("locale", locale)
    moment.locale(locale)
    console.log("locale moment", moment.locale())
    // ShareButton functions

    React.useEffect(() => {
        if (props.urlState) {
            setShareURL(`${window.location.host}/#/tree/${props.urlState}`)
            routerHistory.push(`/tree/${props.urlState}`)
        }
        else {
            setShareURL(window.location.href)
        }

    }, [props.urlState])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Tab {...props} link="false" label="Information" >
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ marginTop: "-1rem" }}
            >
                <h2>{props.record.label}</h2>
                <Button
                    variant="contained"
                    aria-describedby={id}
                    onClick={handleClick}
                    size="small"
                    style={{ width: "6rem", justify: "flex-end" }}
                    startIcon={<ShareIcon />}>
                    {translate('custom.share')}
                </Button>
                <Popover
                    id={id}
                    style={{ marginTop: "1rem" }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MaterialTextField
                        style={{ margin: "1rem" }}
                        variant="outlined"
                        value={shareURL}
                        label="URL"
                        margin="dense"
                    />
                    <Button
                        style={{ margin: "1rem" }}
                        variant="contained"
                        onClick={() => { navigator.clipboard.writeText(shareURL) }}
                        startIcon={<FileCopyIcon />}>{translate('custom.copy')}</Button>
                </Popover>
            </Grid>
            <BoxedShowLayout layoutComponents={[RaBox]}>

                <TextField source="descriptor_id" label="Mesh-ID" />
                <TextField source="classification_no" emptyText="-" />
                <TextField source="annotations" emptyText="-" />
                <TextField source="public_mesh_note" emptyText="-" />
                <TextField source="online_note" emptyText="-" />
                <TextField source="history_note" emptyText="-" />
                <RaBox display="flex" justifyContent="space-between" style={{ marginRight: "1rem" }}>
                    <FunctionField source="date_established" render={record => `${moment(record.date_established, "YYYY-MM-DD").format("LL")}`} />
                    <FunctionField source="date_created" emptyText="-" render={record => `${moment(record.date_created, "YYYY-MM-DD").format("LL")}`} />
                    <FunctionField source="date_revised" emptyText="-" render={record => `${moment(record.date_revised, "YYYY-MM-DD").format("LL")}`} />
                </RaBox>
            </BoxedShowLayout>
            <br />
            <Button href={nlmURL} size="small" target="_blank" variant="outlined">
                {translate('custom.link_nlm')}
            </Button>

        </Tab>
    )
};

InformationTab.propTypes = {
    urlState: PropTypes.string,
    record: PropTypes.object
}

export default InformationTab;