import germanMessages from 'ra-language-german';


//
// This is a custom expansion to the translation messages, used to translate
// terms all across the page. In resources, all column names of the db given 
// by the API will be mapped to translated UI terminology.
//


const customGermanMessages = {
    ...germanMessages,

    custom: {
        swagger: 'API Dokumentation',
        lang: 'Sprache',
        search: 'Suche',
        link_nlm: 'NLM Datenbank',
        privacy: 'Datenschutz',
        imprint: 'Impressum',
        share: 'Teilen',
        searchlanguage: "Deutsch",
        searchlanguagedesc: "Suche nach Wörtern in deutscher Sprache, kann oben rechts geändert werden.",
        copy: "kopieren",
        treetext: "Bei Auswahl eines Begriffes in der MeSH Baumstruktur links werden hier Details angezeigt."
    },
    entry_types: {
        descriptors: 'Hauptbegriffe',
        concepts: 'Konzepte',
        qualifiers: 'Kennzeichner',
        descriptors_scope_notes: "Beschreibung",
        descriptors_annotations: "Annotations"
    },
    resources: {
        descriptors: {
            name: 'Überschriften',
            fields: {
                descriptors: "Überschrift",
                lang: "Sprache",
                allowed_qualifiers_abbreviations: "Abkürzungen",
                descriptor_id: "ID",
                annotations: "Bemerkungen",
                scope_notes: "Notizen",
                classification_no: "Klassifikationsnummer",
                public_mesh_note: "Öffentliche Notiz",
                online_note: "Online Notiz",
                history_note: "Geschichtliche Notiz",
                date_established: "Erstellt am",
                date_revised: "Zuletzt bearbeitet am",
                date_created: "Digitalisiert am",
            }
        },
        terms: {
            name: 'Begriffe',
            fields: {
                label: "Begriffe",
                labellist: "Liste der Begriff",
                term_id: "Begriff-ID",
                lang: "Sprache",
                trnslation_of: "Übersetzung aus",
                thesaurus_list: "Ursprung",
                date_created: "Erstellt am",
                preferred: "Bevorzugt",
                scope_note: "Notizen",
            }
        },
        concepts: {
            name: 'Konzepte',
            fields: {
                label: "Konzept",
                concept_id: "ID",
                system_name: "Herkunft",
                entry_term_text: "Konzept",
                lang: "Sprache",
                trnslation_of: "Übersetzung aus",

            }
        },
        qualifiers: {
            name: 'Kennzeichner',
            fields: {
                subheading_text: "Kennzeichner",
                subheading_abbreviation: "Abkürzung",
                lang: "Sprache"
            }
        },
        search: {
            name: 'Ergebnis |||| Ergebnisse',
            fields: {
                search_text: "MeSh Eintrag",
                lang: "Sprache",
                entry_type: "MeSh Art",
            }
        },

    }
};

export default customGermanMessages;