import englishMessages from 'ra-language-english';


//
// This is a custom expansion to the translation messages, used to translate
// terms all across the page. In resources, all column names of the db given 
// by the API will be mapped to translated UI terminology.
//



const customEnglishMessages = {
    ...englishMessages,

    custom: {
        swagger: 'API documentation',
        lang: 'Language',
        heading: "Heading",
        search: 'Search',
        link_nlm: 'NLM database',
        privacy: 'Privacy',
        imprint: 'Imprint',
        share: 'Share',
        searchlanguage: "English",
        searchlanguagedesc: "Searching for Terms in English, can be changed in upper right corner.",
        copy: "copy",
        treetext: "Upon clicking a Term in the MeSH Treeview on the left, details will be shown here."
    },
    entry_types: {
        descriptors: 'Main Heading',
        concepts: 'Concepts',
        subheadings: 'Qualifiers',
        descriptors_scope_notes: "Scope Notes",
        descriptors_annotations: "Annotations"
    },
    resources: {
        descriptors: {
            name: 'Descriptors',
            fields: {
                label: "Descriptor",
                lang: "Language",
                allowed_qualifiers_abbreviations: "Abbreviations",
                descriptor_id: "ID",
                annotations: "Annotations",
                scope_notes: "Scope Notes",
                classification_no: "Classification Number",
                public_mesh_note: "Public MeSH Note",
                online_note: "Online Note",
                history_note: "History Note",
                date_established: "Established on",
                date_revised: "Revised on",
                date_created: "Created on",
            }
        },
        terms: {
            name: 'Terms',
            fields: {
                label: "Terms",
                labellist: "List of Terms",
                term_id: "Term-ID",
                lang: "Language",
                translation_of: "Tranlated from",
                thesaurus_list: "Origin",
                date_created: "Created on",
                preferred: "Preferred",
                scope_note: "Scope Note",

            }
        },
        concepts: {
            name: 'concepts',
            fields: {
                label: "Concept",
                concept_id: "ID",
                system_name: "Origin",
                entry_term_text: "Synonym",
                lang: "Language",
                translation_of: "Translated from"
            }
        },
        qualifiers: {
            name: 'Qualifiers',
            fields: {
                subheading_text: "Qualifier",
                subheading_abbreviation: "Abbreviation",
                lang: "Language"
            }
        },
        search: {
            name: 'Result |||| Results',
            fields: {
                search_text: "MeSh Entry",
                lang: "Language",
                entry_type: "MeSh Type",
            }
        },
    }
};

export default customEnglishMessages;