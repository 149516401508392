import * as React from 'react';
import PropTypes from 'prop-types';
import {
    TabbedShowLayout,
    Show,
    useShowController,
    SimpleShowLayout,
    useLocale,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import InformationTab from "./TabbedHeadingView/InformationTab";
import ConceptTab from "./TabbedHeadingView/ConceptTab";
import SubHeadingTab from "./TabbedHeadingView/SubHeadingTab";
import MeshTreeView from './TabbedHeadingView/MeshTreeView';


//
// Wrapper for InformationTab, Subheading & Entryterm Tabs & TreeView
//

const rootEntryTerm = 'root'

const HeadingViewTabbed = (props) => {
    const locale = useLocale();
    const [currentID, setCurrentID] = React.useState(rootEntryTerm);
    const data = props.dataMainTree
    const { record } = useShowController((props));
    return (
        <Container maxWidth="xl" style={{ paddingTop: "1rem" }}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xl={8} md={5}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xl={6}
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        style={{ padding: "1rem" }}>
                        <Show {...props} actions={null}>
                            <SimpleShowLayout>
                                {props.basePath === '/tree' ? ((currentID !== rootEntryTerm) &&
                                    <InformationTab rootEntryTerm={rootEntryTerm} resource='descriptors' basePath='/descriptors' record={data} />) :
                                    <InformationTab />}
                            </SimpleShowLayout>
                        </Show>
                    </Grid>
                    <Grid item xl={6}
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                        style={{ padding: "1rem" }}>
                        <Show {...props} actions={null}>
                            <TabbedShowLayout link="false" >
                                <ConceptTab record={record} />
                                <SubHeadingTab record={record} path="qualifiers" />
                            </TabbedShowLayout>
                        </Show>
                    </Grid>
                </Grid>
                <Grid item xl={4} md={7}
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    style={{ padding: "1rem" }}
                >
                    {props.basePath === '/tree' ?
                        <MeshTreeView {...props} id={`["${rootEntryTerm}","${locale}"]`} basePath="/tree" setCurrentID={setCurrentID} ingeneralview={true} resource="descriptors" />
                        :
                        <MeshTreeView {...props} ingeneralview={false} record={record} />}
                </Grid>
            </Grid>
        </Container>
    );
};
HeadingViewTabbed.propTypes = {
    basePath: PropTypes.string,
    dataMainTree: PropTypes.object
}
export default HeadingViewTabbed;