import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { Show, SimpleShowLayout } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { sortBy } from 'lodash';
import MeshTreeItem from './MeshTreeItem';

//
// Creates a TreeView
//

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        margin: "1rem",
    },
    treeSelf: {
        color: "#c1002a"
    },

});

const MeshTree = props => {
    const tree = props.nodes;
    const setCurrentID = props.setCurrentID
    const classes = useStyles();
    const initExpand =  (tree.parent ?
        tree.parent.concat(tree.self).map(node => node.classification_code)
        : tree.self.map(node => node.classification_code));

    
    let toExpand = []
    if (props.ingeneralview) {
        if (props.prevExpanded.length === 0){
            toExpand = initExpand
        } else {
            toExpand = props.prevExpanded
        }
    } else {
        toExpand = initExpand
    }
    const treeView = window.location.href.includes("tree")

    const [expanded, setExpanded] = React.useState(toExpand);

    const [selected, setSelected] = React.useState(props.prevSelected );

    const setPrevExpanded = (props.setPrevExpanded || "")
    const setPrevSelected = (props.setPrevSelected || "")


    useEffect(() => {
        if (props.setPrevExpanded) {
            setPrevExpanded(expanded)
            setPrevSelected(selected)
            
        }
    }, [expanded, selected, props.setPrevExpanded, setPrevExpanded, setPrevSelected])

    useEffect(() => {
        setSelected(props.prevSelected)
    }, [props.prevSelected])

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event, treeId) => {

        setSelected(treeId);
    };


    const renderSelf = (tree) => {
        if (tree.child) {
            return (
                <TreeItem
                    classes={{root: classes.treeSelf}}
                    nodeId={tree.self[0].classification_code}
                    label={tree.self[0].label}>
                    {
                        tree.child.map((entry) => (
                            <div>
                                <MeshTreeItem {...props} setCurrentID={setCurrentID} entry={entry} />
                            </div>
                        ))
                    }
                </TreeItem>
            )
        }
        else {
            return (
                <TreeItem
                    classes={{root: classes.treeSelf}}
                    nodeId={tree.self[0].classification_code}
                    label={tree.self[0].label} />
            )
        }
    };

    const renderNeighbors = (tree) => {
        if (tree.neighbor) {
            let entries = sortBy(tree.neighbor.concat(tree.self), 'label');

            return (
                <React.Fragment>
                    {entries.map((entry) => (
                        <React.Fragment>
                            {entry.node_type === "self" ? renderSelf(tree) : <MeshTreeItem  entry={entry} setCurrentID={setCurrentID}/>}
                        </React.Fragment>
                    )
                    )}
                </React.Fragment>
            )
        }
        else {
            return renderSelf(tree)
        }
    };

    const renderParents = (tree, depth) => {
        if (tree.parent && tree.parent[depth]) {

            const entry = tree.parent[depth];
            return (
                <MeshTreeItem {...props} entry={entry} setCurrentID={setCurrentID}>
                    {renderParents(tree, depth + 1)}
                </MeshTreeItem >
            )
        }
        else {
            return renderNeighbors(tree)
        }
    };

    const sortParents = (tree) => {
        //
        // Parents are send in unreliable order by API, this sorts tham based on length of ccode
        //
        if (tree.parent) {
            tree.parent = sortBy(tree.parent, 'classification_code')
        };
        return (
            renderParents(tree, 0)
        )
    }
        

    return (
        <Show {...props} actions={null} >
            <SimpleShowLayout >
                <Grid item xs={12}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                    style={{  overflowY: "auto", height: (treeView ? window.innerHeight * 0.8 : "auto") }}
                >
                    <TreeView
                        className={classes.root}
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        selected={selected}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                        defaultExpanded={expanded}
                    >
                        <div>
                            {sortParents(tree)}
                           
                        </div>
                    </TreeView>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

MeshTree.propTypes = {
    nodes: PropTypes.object,
    setCurrentID: PropTypes.string,
    prevExpanded: PropTypes.array,
    setPrevSelected: PropTypes.func,
    setPrevExpanded: PropTypes.func,
    prevSelected: PropTypes.array,
    ingeneralview: PropTypes.bool
}

export default MeshTree;