import * as React from 'react';
import PropTypes from 'prop-types'
import {
    Datagrid,
    Tab,
    ReferenceManyField,
    TextField,
    Pagination,
    useTranslate,
    useLocale
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

//
// Tab for detail view to display all subheadings/qualifiers
//
const useStyles = makeStyles({
    headerCell: {
        fontWeight: '600'
    },
})

const SubHeadingTab = (props) => {
    // const { record } = useShowController(props);
    const record = props.record
    const translate = useTranslate()
    const classes = useStyles(props);
    const locale = useLocale()

    return (
        <Tab {...props}  label={translate('resources.qualifiers.name')} >
        <ReferenceManyField
            record={record}
            // link="false"
                addLabel={false}
            basepath={props.basepath}
            source="descriptor_id"
            target="descriptor_id"
            reference="qualifiers"
            perPage={15}
            filter={{ lang: locale }}
            pagination={<Pagination rowsPerPageOptions={[]}/>}>
                <Datagrid classes={classes}>
                <TextField source="qualifier_abbreviation"/>
                <TextField source="label" />
            </Datagrid>
        </ReferenceManyField>
    </Tab>
    )
};

SubHeadingTab.propTypes = {
    basepath: PropTypes.string,
    record: PropTypes.object
}

export default SubHeadingTab;