import * as React from 'react';
import { useLocale, useSetLocale, useGetList } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TranslateIcon from '@material-ui/icons/Translate';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-admin"
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

//
// Builds the Custom Bar on the top of the application
//


const useStyles = makeStyles((theme) => ({

    header: {
        backgroundColor: "#014a99",
        boxShadow: "none",
    },
    logoButton: {
        marginRight: theme.spacing(2),
        padding: 4
    },
    translateButton: {
        justifyContent: "right",
        color: '#fafafa'
    },
    treeButton: {
        justifyContent: "right",
        marginRight: 5,
        color: '#fafafa'
    },
    title: {
        flexGrow: 1,
        color: '#fafafa'
    },
    translateMenu: {
        flexGrow: 1,
        color: '#fafafa'
    },
}));



const CustomAppBar = (props) => {
    // 
    const locale = useLocale();
    const setLocale = useSetLocale();
    const classes = useStyles();


    const [anchorEl, setAnchorEl] = React.useState(null);

    // Geting possible languages from API
    const languages_data = useGetList('languages', { page: 1, perPage: 10 },
        { field: 'iso2', order: 'ASC' }, {});
    const languages = languages_data.data;

    const possibleLanguages = (languages) => {
        // inserts possible languages as dropdown select options
        return Object.keys(languages).map((key, value) =>
            <MenuItem onClick={() => langHandleClick(key)} key={key}>{languages[key].language_name}</MenuItem>);
    }



    const handleClick = (event) => {

        // Toggles language dropdown after click on Button

        setAnchorEl(event.currentTarget);
    };

    const langHandleClick = (key) => {

        // THIS HAS TO BE REWORKED, RegEx not the nicest way

        // Changes language in URL (within compound key of entry) according to selected language
        // for:


        setLocale(languages[key].iso2);
        localStorage.setItem('customLocale', languages[key].iso2);
        // search filter URL
        // window.location.href = window.location.href.replace(/%22lang%22%3A%22(..)%22/, "%22lang%22%3A%22" + languages[key].iso2 + "%22")

        var url = window.location.href;

        var reg = /\[%22(\w+)%22,%22(..)%22\]/g;
        var matches = reg.exec(url);
        if (url.includes("/descriptors/[")) {
            // Detail view
            window.location.href = window.location.href.replace(/\[%22(\w+)%22,%22(..)%22\]/, '["' + matches[1] + '","' + key + '"]')
        }

        // Closes dropdown after language select

        setAnchorEl(null);
    }

 
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.root}>
            <AppBar className={classes.header}>
                <Toolbar>
                    <IconButton
                        className={classes.logoButton}
                        color="inherit"
                        aria-label="menu"
                        component={Link} // This throws warnings, but is RA standard.
                        to={{ pathname: '/' }}><img src="/logo512.png" alt="Example Logo" style={{ width: "40px" }} />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        MeSH Browser
                    </Typography>
                    <Button
                        edge="end"
                        component={Link}
                        className={classes.treeButton}
                        to={{ pathname: '/tree/{"MeshID":"root","expanded":[""],"selected":"root"}' }}
                    >
                        <AccountTreeOutlinedIcon fontSize="small" edge="end" style={{ paddingRight: "5px"}}/>{"Tree Browser"}
                    </Button>
                    <Button
                        edge="end"
                        className={classes.translateButton}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}

                    >
                        <TranslateIcon fontSize="small" edge="end" style={{ paddingRight: "5px" }} />  {locale} <ArrowDropDownIcon/>
                    </Button>
                    <Menu
                    style={{marginTop: "3rem"}}
                        edge="end"
                        id="simple-menu"
                        anchorEl={anchorEl}
                        className={classes.translateMenu}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {possibleLanguages(languages)}
                    </Menu>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default CustomAppBar;