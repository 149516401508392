export const getLinkTarget = (record) => {
  let linktarget = ""
  if (record.entry_type === "descriptors") {
    linktarget = '["' + record.entry_key + '","' + record.lang + '"]'
  }
  else {
    linktarget = '["' + record.reference_id + '","' + record.lang + '"]'
  }

  return linktarget;
};