import React from 'react';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import TreeItem from '@material-ui/lab/TreeItem';
import { Link } from 'react-admin';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import ChildrenLoader from './ChildrenLoader'

//
// Creates Tree listings and calls funtion to get more children
//

const useStyles = makeStyles({
  treeItem: {
    color: "#212121",
  },
  selected: {
    color: "#c1002a"
  },
  icon: {
    fontSize: "medium",
    color: "#8e8e8e",
    // paddingLeft: "-1rem"
  },
  link: {
    float: 'right',
    // paddingLeft: '2rem'
  }
});


export default function MeshTreeItem(props) {
  const classes = useStyles();
  const entry = props.entry;
  const hasChildren = props.entry.child_count > 0;
  const setCurrentID = props.setCurrentID
  const isNoCategory = entry.descriptor_id.length > 1;

  function updateMeshID() {
    if (setCurrentID) {
      const currentID = entry.descriptor_id;
      props.setCurrentID(currentID);
    }
  }

  return (<TreeItem
    classes={{ root: classes.treeItem, selected: classes.selected }}
    onClick={() => updateMeshID()}
    nodeId={entry.classification_code}
    label={
      <React.Fragment>
        <span>{entry.label}</span>
        {isNoCategory && <Link _target="_blank" className={classes.link} to={`/descriptors/${'["' + entry.descriptor_id + '","' + entry.lang + '"]/show'}`}>
          <SubdirectoryArrowRightIcon className={classes.icon} />
        </Link>}
      </React.Fragment>}
  >
    {props.children || (hasChildren && <ChildrenLoader {...props} setCurrentID={setCurrentID} />)}
  </TreeItem>);
};

MeshTreeItem.propTypes = {
  children: PropTypes.object,
  setCurrentID: PropTypes.func,
  entry: PropTypes.object
}