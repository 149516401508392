import React from 'react';
import {Link, useTranslate} from 'react-admin';
//
// Custom Footer to overwrite default menu
//

const CustomFooter = (props) => {
    const translate = useTranslate();
    return (
        <div style={{
            position: 'fixed', right: 0, bottom: 0, left: 0, zIndex: 100,
            padding: 6,
            backgroundColor: '#efefef',
            textAlign: 'center'
        }}>
            <Link to="/swagger-ui">{translate('custom.swagger')}</Link>
            <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
            <Link to="/privacy">{translate('custom.privacy')}</Link>
            <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
            <Link to="/imprint">{translate('custom.imprint')}</Link>
        </div>)
};

export default CustomFooter;