import * as React from "react";
import { Route } from 'react-router-dom';
import LandingPage from 'components/LandingPage';
import Imprint from 'components/Footer/Imprint';
import Privacy from 'components/Footer/Privacy';
import TreeViewer from 'components/TreeViewer';

import SwaggerConfigured from 'components/SwaggerConfigured';

//
// Used to route to landing Page
//

const customRoutes = [
    <Route exact path="/" component={LandingPage}/>,
    <Route exact path="/imprint" component={Imprint}/>,
    <Route exact path="/privacy" component={Privacy}/>,
    <Route exact path="/tree" component={TreeViewer}/>,
    <Route path="/tree/:treeStateUrl" component={TreeViewer}/>,
    <Route exact path="/swagger-ui" component={SwaggerConfigured} />
];

export default customRoutes;