import * as React from 'react';
import {useTranslate} from 'react-admin'
import Chip from '@material-ui/core/Chip';
import get from 'lodash/get';

//
// ChipField to translate and map label for entry_types in main List
//


const CustomChipField = ({ source, record }) => {
    const translate = useTranslate();
    const label = {
        "descriptors": translate('entry_types.descriptors'),
        "concepts": translate('entry_types.concepts'),
        "subheadings": translate('entry_types.subheadings'),
        "descriptors_scope_notes": translate("entry_types.descriptors_scope_notes"),
        "descriptors_annotations": translate("entry_types.descriptors_annotations")
    };
    return (
        <Chip label={label[get(record, source)]} />
    )
};
export default CustomChipField;