import * as React from 'react';
import MeshTreeView from './TabbedHeadingView/MeshTreeView';
import { useParams } from "react-router-dom";
import {
  useLocale,
  useTranslate
} from 'react-admin';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TreeInformation from './TreeViewer/TreeInformation';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

const rootEntryTerm = 'root'

export const TreeViewer = (props) => {
  const locale = useLocale();
  const [currentID, setCurrentID] = React.useState(rootEntryTerm);
  const [urlState, setUrlState] = React.useState("");
  const translate = useTranslate();

  let { treeStateUrl } = useParams();

  return (
    <Container maxWidth="xl"  >
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ overflow: "hidden" }}
      >
        <Grid item md={7}
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          style={{ marginTop: "1rem" }}>
          <MeshTreeView
            {...props}
            id={`["${rootEntryTerm}","${locale}"]`}
            basePath="/tree"
            setCurrentID={setCurrentID}
            currentID={currentID}
            ingeneralview={true}
            resource="descriptors"
            treeStateUrl={treeStateUrl}
            setUrlState={setUrlState}
          />
        </Grid>
        <Grid item md={5}
          container
          direction="column"
          alignItems="stretch"
          style={{ padding: "1rem", marginTop: "1rem" }}
        >
          <Card style={{ padding: "1rem" }}>
            <Grid item xs={12}
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              style={{ overflowY: "auto", height: window.innerHeight * 0.8 }}
            >
              {(currentID !== rootEntryTerm && currentID.length > 1) && <TreeInformation rootEntryTerm={rootEntryTerm} currentID={currentID} urlState={urlState} />}
              {(currentID === rootEntryTerm || currentID.length <= 1) && <React.Fragment alignItems="center"><p>{translate("custom.treetext")}</p> <KeyboardReturnIcon /></React.Fragment>}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
export default TreeViewer;