import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import LandingPageSearch from './LandingPage/Search';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

//
// Landing Page
//


const useStyles = makeStyles(theme => ({
  marginAuto: {
    marginTop: '6%',
    justifyContent: "flex-start",
    display: 'flex',
    alignItems: 'center',

},
  root: {
    width: '100%',
    backgroundColor:"inherit",
    boxShadow: "none"
  }
}))

const LandingPage = (props) => {
  const classes = useStyles();
  return (
    <Container className={classes.marginAuto} >
      <Card className={classes.root} width="auto">
        <CardContent>
          <LandingPageSearch />
        </CardContent>
      </Card>
    </Container>
  );
};
export default LandingPage;