// in src/App.js
import React from 'react';
import { fetchUtils, Admin, Resource, resolveBrowserLocale } from 'react-admin';
import postgrestRestProvider from '@raphiniert/ra-data-postgrest';
// import { createTheme } from '@material-ui/core/styles';

import config from "config.json";

import Footer from 'components/Footer';

import ResultsList from 'components/ResultsList'
import HeadingViewTabbed from 'components/TabbedHeadingView';
import { CustomLayout } from 'Layout/Layout';
import customRoutes from 'customRoutes';

// For Translation

import polyglotI18nProvider from 'ra-i18n-polyglot';
import customGermanMessages from 'i18n/de';
import customEnglishMessages from 'i18n/en';

// const development = true;

//
// This sets up the translation functionality of React Admin
//

const messages = {
  'de': customGermanMessages,
  'en': customEnglishMessages
};

const i18nProvider = polyglotI18nProvider(
  locale => messages[locale] ? messages[locale] : messages.de,
  (localStorage.getItem('customLocale') || resolveBrowserLocale())

);


//
// This sets up the dataProvider for PostGrest API with custom compound mapping
//


const dataProvider = postgrestRestProvider(config.rest_url, fetchUtils.fetchJson, 'eq', new Map([
  ['concepts', ['concept_id']],
  ['descriptors', ['descriptor_id', 'lang']],
  ['qualifiers', ['descriptor_id', 'qualifier_id']],
  ['subheadings', ['subheading_abbreviation', 'lang']],
  ['heading_subheading_pairs', ['main_heading_mesh_id', 'subheading_abbreviation']],
  ['languages', ['iso2']],
  ['search', ['entry_key', 'lang']],
  ['rpc/get_term_trees', ['mesh_id', 'lang']],
  ['rpc/get_inner_term_children', ['mesh_id', 'lang', 'ccode']]
]));

//
// Main Application Defintion
//
//
// Currently, locale='de' seems to have no effect?!
//

const App = () => (
  
    <Admin
      i18nProvider={i18nProvider}
      title='MeSH Suchmaschine'
      dataProvider={dataProvider}
      layout={CustomLayout}
      customRoutes={customRoutes}
    >
      <Resource name="descriptors" show={HeadingViewTabbed} />
      <Resource name="concepts" />
      <Resource name="subheadings" />
      <Resource name="qualifiers" />
      <Resource name="heading_subheading_pairs" />
      <Resource name="languages" />
      <Resource name="search" options={{ label: 'Search' }} list={ResultsList} />
      <Resource name="rpc/get_term_trees" />
      <Footer />
    </Admin>
);

export default App;
