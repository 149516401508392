import React, { useEffect } from 'react';
import { useQueryWithStore } from 'react-admin';
import LoadingCircle from './MeshTreeView/LoadingCircle';
import MeshTree from './MeshTreeView/MeshTree';
import PropTypes from 'prop-types';

//
// Calls API to get Meshtree data and build tree for each one
//

export default function MeshTreeView(props, record) {

  const setCurrentID = props.setCurrentID
  const [prevExpanded, setPrevExpanded] = React.useState([])
  const [prevSelected, setPrevSelected] = React.useState([])
  const ingeneralview = props.ingeneralview || false
  let setUrlState = props.setUrlState

  useEffect(() => {
    if (ingeneralview && props.treeStateUrl) {
      let treeState = JSON.parse(props.treeStateUrl)
      setPrevExpanded(treeState.expanded)
      setPrevSelected(treeState.selected)
      setCurrentID(treeState.MeshID)
    }
  }, [setCurrentID, setPrevSelected, setPrevExpanded, ingeneralview, props.treeStateUrl])

  useEffect(() => {
    if (setUrlState){
      setUrlState(JSON.stringify({ "MeshID": props.currentID, "expanded": prevExpanded, "selected": prevSelected}))
    }
  }, [prevSelected, prevExpanded, props.currentID, setUrlState])


  const { data, loading, error } = useQueryWithStore({
    type: 'getMany',
    resource: 'rpc/get_term_trees',
    payload: { ids: [props.id] }
  });

  if (loading) return <LoadingCircle />;
  if (error) return null;
  if (!data) return null;

  return (
    data.map(
      nodes => (
        <MeshTree
          nodes={nodes}
          prevSelected={prevSelected}
          setPrevExpanded={setPrevExpanded}
          setPrevSelected={setPrevSelected}
          prevExpanded={prevExpanded}
          setCurrentID={setCurrentID}
          ingeneralview={ingeneralview}
          {...props} />
      )
    )
  )
};

MeshTreeView.propTypes = {
  id: PropTypes.string,
  currentID: PropTypes.string,
  treeStateUrl: PropTypes.string,
  ingeneralview: PropTypes.bool
}