import * as React from 'react';
import {
    List,
    Datagrid,
    Filter,
    SearchInput,
    useLocale,
    TextField,
    Pagination
} from 'react-admin';
import Container from '@material-ui/core/Container';
import CustomChipField from './ResultsList/CustomChipField';
import { makeStyles } from '@material-ui/core';
import { getLinkTarget } from 'helper';

//
// Main List for Search results
//

const useStyles = makeStyles({
    headerCell: {
        fontWeight: '600'
    },
    

});

const ZomboFilter = props => (
    <Filter {...props}>
        <SearchInput source="query" alwaysOn autoFocus />
    </Filter>
);

const PostPagination = props => <Pagination rowsPerPageOptions={[15, 25, 50, 100]} {...props} />;

export const ResultsList = (props) => {
    const locale = useLocale();
    const classes = useStyles(props);

    return (
        <Container maxWidth="xl">
        <List {...props}
                pagination={<PostPagination />}
            filters={ <ZomboFilter/>}
            filter={{ lang: locale }}
                perPage="15"
            sort={{ field: 'score', order: 'DESC' }}
            bulkActionButtons={false}
            actions={null}
            >
                <Datagrid classes={classes} {...props} rowClick={(id, basePath, record) => `/descriptors/${getLinkTarget(record)}/show`}>
                <TextField source="search_text" />
                <TextField {...props}/>
                <CustomChipField source="entry_type"/>
                <TextField source="score"/>
            </Datagrid>
        </List>
        </Container>
    );
};
export default ResultsList;