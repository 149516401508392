import React from 'react';
import PropTypes from 'prop-types'
import { useQueryWithStore } from 'react-admin';
import MeshTreeItem from './MeshTreeItem'
import LoadingCircle from './LoadingCircle'

const ChildrenLoader = (props) => {

    //
    // Loads Children of Neighbors of Self on Click
    //
    
    const setCurrentID = props.setCurrentID
    const id = `["${props.entry.descriptor_id}", "${props.entry.lang}", "${props.entry.classification_code}"]`;

    const { data, loading, error } = useQueryWithStore({
        type: 'getOne',
        resource: 'rpc/get_inner_term_children',
        payload: { id: id }
    });


    if (loading) return <LoadingCircle />;
    if (error) return null;
    if (!data) return null;

    return (
        data.child.map((entry) => (
            <div>
                <MeshTreeItem entry={entry} setCurrentID={setCurrentID} />
            </div>
        )
        )
    )
};

ChildrenLoader.propTypes = {
    setCurrentID: PropTypes.func,
    entry: PropTypes.object
}

export default ChildrenLoader;