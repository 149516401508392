import * as React from 'react';
import PropTypes from 'prop-types'
import {
    Datagrid,
    Tab,
    ReferenceManyField,
    TextField,
    Pagination,
    useLocale,
    useTranslate
} from 'react-admin';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Chip from '@material-ui/core/Chip';


const TableCell = withStyles({
    root: {
        borderBottom: "none"
    }
})(MuiTableCell);



const PostPanel = ({ id, record, resource }) => {
    const translate = useTranslate()
    console.log("record", record)

    let current = record.term_list

    const useStyles = makeStyles({
        expandIconCell: {
            borderBottom: "none"
        },
        tableHead: {
            color: "rgba(0, 0, 0, 0.54)",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            lineHeight: "1",
            letterSpacing: " 0.00938em",
            fontSize: "0.8rem",
            paddingBottom: 0,

        },
        rowCell: {
            borderBottom: "none",
            color: (record.preferred === "true" ? "#c1002a" : "inherit")
        },
        root: {
            width: "100%"
        },

        MuiFormControlRoot: {
            display: "block"
        }


    });
    const classes = useStyles();


    return (
        <Tab>
            <TextField source="scope_note" emptyText="-" label={translate('resources.terms.fields.scope_note')} />
            <TextField label={translate('resources.terms.fields.labellist')} />
            <TableContainer>
                <Table classes={{ root: classes.root }} aria-label="simple table">
                    <TableHead >
                        <TableCell classes={{ root: classes.tableHead }}>{translate('resources.terms.fields.label')}</TableCell>
                        <TableCell classes={{ root: classes.tableHead }} align="right">{translate('resources.terms.fields.term_id')}</TableCell>

                        <TableCell classes={{ root: classes.tableHead }} align="right">{translate('resources.terms.fields.thesaurus_list')}</TableCell>
                        <TableCell classes={{ root: classes.tableHead }} align="right">{translate('resources.terms.fields.date_created')}</TableCell>
                        {/* <TableCell align="right" hidden>{translate('resources.terms.fields.preferred')}</TableCell> */}
                    </TableHead>
                    <TableBody>
                        {current.map((row) => (
                            <TableRow key={row.term_id}>
                                <TableCell component="th" scope="row">
                                    {row.label}
                                </TableCell>
                                <TableCell align="right">{row.term_id}</TableCell>

                                <TableCell align="right">{row.thesaurus_list && row.thesaurus_list.map(element => <Chip size="small" label={element} />)}</TableCell>
                                <TableCell align="right">{row.date_created}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Tab>

    );

}




const postRowStyle = (record, index) => ({
    backgroundColor: record.preferred === true ? '#e0e0e0' : 'white',
});


//
// Tab to display entry terms
//

const ConceptTab = (props) => {
    const record = props.record
    const useStyles = makeStyles({
        headerCell: {
            fontWeight: 'bold'
        },
        expandIconCell: {
            borderBottom: "none"
        },
        tableHead: {
            color: "rgba(0, 0, 0, 0.54)",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            lineHeight: "1",
            letterSpacing: " 0.00938em",
            fontSize: "0.8rem",
            paddingBottom: 0,

        },
        rowCell: {
            borderBottom: "none",
            color: "inherit"
        },
        rowCell2: {
            borderBottom: "none",
            color: "#c1002a"
        },

        root: {
            display: "block"
        },

        MuiFormControlRoot: {
            display: "block"
        }


    });
    const classes = useStyles(props);

    const locale = useLocale()
    const translate = useTranslate()

    return (
        <Tab {...props} contentClassName={classes.root} label={translate('resources.concepts.name')}>
            <ReferenceManyField
                headerClassName={{ root: classes.root }}
                record={record}
                addLabel={false}
                basepath={`/descriptors/${record.id}`}
                source="descriptor_id"
                target="descriptor_id"
                reference="concepts"
                sort={{ field: 'preferred', order: 'DESC' }}
                perPage={15}
                filter={{ lang: locale }}
                pagination={<Pagination rowsPerPageOptions={[]}
                />}>
                <Datagrid
                    classes={{  expandIconCell: classes.expandIconCell, headerCell: classes.headerCell }}
                    className={classes.table}

                    rowStyle={postRowStyle}
                    isRowExpandable={row => (row.term_list || "").length >= 1}
                    expand={<PostPanel />}>
                    <TextField source="label"  classes={{ root: (record.preferred === true ? classes.rowCell2 : classes.rowCell) }} />
                    <TextField source="concept_id"  />
                </Datagrid>
            </ReferenceManyField>
        </Tab>
    )
};

ConceptTab.propTypes = {
    record: PropTypes.object
}

export default ConceptTab;