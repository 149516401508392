import React from 'react';

export default function Imprint() {
    const url = <i>mesh-browser.de</i>;

    return(
        <div style={{margin: '2em'}}>
          <h2>Impressum</h2>
          <p><strong>Herausgeber</strong>
          <br /> Albert-Ludwigs-Universität Freiburg
          <br /> Breisacher Straße 153
          <br /> D 79110 Freiburg
          <br /> Telefon: +49 761 270-0
          <br /> Telefax: +49 761 270-20200
          <br /><a href="mailto:info@uniklinik-freiburg.de" target="_blank" rel="noopener noreferrer">info@uniklinik-freiburg.de</a>
          </p>
          <p><strong>Gesamtverantwortung</strong>
            <br />Das Universitätsklinikum Freiburg ist eine rechtsfähige Anstalt des öffentlichen Rechts der Albert-Ludwigs-Universität Freiburg, die der Rechtsaufsicht durch das Ministerium für Wissenschaft, Forschung und Kunst Baden-Württemberg unterliegt. Es wird vertreten durch den Leitenden Ärztlichen Direktor, Prof. Dr. Frederik Wenz und die Kaufmännische Direktorin, Anja Simon. Umsatzsteuer-Identifikationsnummer: DE 811506626
        </p>
          <p><strong>Inhaltlich verantwortliche Person</strong>
          <br />Raphael Scheible
          <br />Institut für Medizinische Biometrie und Statistik (IMBI)
          <br />Albert-Ludwigs-Universität Freiburg
          <br />Stefan-Meier-Str. 26
          <br />D 79104 Freiburg
          <br />
          <br />Gefördert mit Mitteln des Bundesministerium für Bildung und Forschung.
          <br />Förderkennzeichen: 01ZZ1801B, 01ZZ1804A
        </p>
          <p><strong>Inhalte dieser Website</strong>
            <br />Die Inhalte von {url} werden mit größtmöglicher Sorgfalt erstellt. Eine Gewähr für die Richtigkeit, Aktualität und Vollständigkeit der Inhalte kann dennoch nicht übernommen werden. Die angebotenen Gesundheitsinformationen schränken die Therapiefreiheit nicht ein. Für Therapieentscheidungen trägt der verordnende Arzt die medizinische und haftungsrechtliche Verantwortung. Nutzen und Risiken sind stets sorgfältig unter Einbeziehung von Therapiealternativen abzuwägen. Eine medizinisch-fachliche Begründung der Therapieentscheidung ist erforderlich. <br />
            Unsere MeSH-Inhalte basieren auf der Übersetzung des <a href="https://www.dimdi.de/dynamic/de/klassifikationen/weitere-klassifikationen-und-standards/mesh/">Deutschen Instituts für Medizinische Dokumentation und Information</a>
            </p>
          <p><strong>Externe Links</strong>
            <br />{url} enthält Links zu externen Webseiten Dritter, für deren Inhalte wir keine Gewähr übernehmen können. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
          </p>
          <p><strong>Copyright-Hinweis zu Fotos und Grafiken</strong>
            <br />Alle verwendeten Bilder, soweit nicht anders ausgezeichnet: Copyright Institut für Medizinische Biometrie und Statistik, Universitätsklinikum Freiburg
          </p>
          <p><strong>Anwendbares Recht</strong>
            <br />Es gilt ausschließlich das maßgebliche Recht der Bundesrepublik Deutschland.
          </p>
          <p><strong>Impressum des Universitätsklinikums Freiburg</strong>
            <br />Bitte beachten Sie auch das <a href="https://www.uniklinik-freiburg.de/footernavigation/impressum.html" target="_blank" rel="noopener noreferrer">Impressum des Universitätsklinikums Freiburg</a>, das, soweit anwendbar, auch für diese Seite gilt.
          </p>

          <p><strong>Gesamtrealisation</strong>
            <br />Institut für Medizinische Biometrie und Statistik (IMBI)
            <br />Albert-Ludwigs-Universität Freiburg
            <br />Stefan-Meier-Str. 26
            <br />D 79104 Freiburg
            <br /><a href="https://www.uniklinik-freiburg.de/imbi.html" target="_blank" rel="noopener noreferrer">www.uniklinik-freiburg.de/imbi.html</a>
          </p>
          <p><strong>Gender Disclaimer</strong>
            <br />Aus Gründen der Lesbarkeit wurde im Text die männliche Form gewählt, nichtsdestoweniger beziehen sich die Angaben auf Angehörige beider Geschlechter.
          </p>
        </div>
    )
}