import * as React from "react";
import { List, Datagrid, useLocale, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  thead: {
    display: 'none',
  },
});

export const SuggestionDropdown = props => {
  const locale = useLocale();
  const classes = useStyles();

  return (
    <List {...props}
      filter={{ lang: locale, query: props.query }}
      sort={{ field: 'score', order: 'DESC' }}
      bulkActionButtons={false}
      actions={null}
      pagination={null}
      title={null}
    >
      <Datagrid classes={{ thead: classes.thead }} rowClick={props.rowClick}>
        <TextField source="search_text" />
      </Datagrid>
    </List>
  )
};
export default SuggestionDropdown;