import * as React from 'react';
import { Layout } from 'react-admin';
import AppBar from './AppBar';


//
// Custom Layout, used to hand over custom AppBar and empty Sidebar to Application
//


export const CustomLayout = (props) => {

    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={(props) => null}
        />
    );
};