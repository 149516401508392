import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from '@material-ui/core/Button';
import { useLocale, useTranslate } from 'react-admin';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SuggestionDropdown from './Search/SuggestionDropdown';
import Tooltip from '@material-ui/core/Tooltip';
import { getLinkTarget } from 'helper';

//
// Landing Page of Application
//  Routes all searches with >2 Characters to Zombo_Search
//  Method change disabled

const useStyles = makeStyles((theme) => ({
    typographyPopover: {
        padding: theme.spacing(1),
    },
}));

export default function LandingPageSearch(props) {
    const searchEndpoint = "/search";
    const classes = useStyles();
    const locale = useLocale();
    const [searchInput, setSearchInput] = React.useState('');
    const translate = useTranslate();

    const [anchorEl, setAnchorEl] = React.useState(null);


    const changeInput = (event) => {
        setSearchInput(event.target.value);
    };


    const submitSearch = (event) => {
        if (event.keyCode === 13) {
            if (searchInput.length > 2) {
                window.location.href = `/#${searchEndpoint}/?filter=${mergeSearchAndMethod()}`;
            }
            else {
                setAnchorEl(event.currentTarget);
            }
        }
        else if (event._reactName === "onClick") {
            if (searchInput.length > 2) {
                window.location.href = `/#${searchEndpoint}/?filter=${mergeSearchAndMethod()}`;
            }
            else {
                setAnchorEl(event.currentTarget);
            }
        }
    }


    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };


    //
    //Currently not used, Helpers for changing search method and endpoint
    //


    const mergeSearchAndMethod = () => {
        let obj = {};
        obj["lang"] = locale;
        obj["query"] = searchInput;
        return JSON.stringify(obj);
    }

    return (
        <Container maxWidth="xl">
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item sm={6}
                    container
                    direction="column"
                    justify="center"
                    alignItems="center">
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-end"
                        style={{paddingBottom: "2rem"}}
                    >
                        <img src="/logo512.png" alt="MeSH Search Logo" style={{ width: "30%"}} />
                        <Tooltip title={translate("custom.searchlanguagedesc")} placement="right">
                        <em style={{ marginLeft: "-20px"}}>{translate("custom.searchlanguage")}</em>
                        </Tooltip>
                    </Grid>
                    <FormControl fullWidth >
                        <TextField
                            autoComplete="off"
                            autoFocus
                            id="Searchterm"
                            variant="outlined"
                            label={translate('custom.search')}
                            //className={classes.textfield}
                            onChange={changeInput}
                            onKeyDown={submitSearch}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <Button
                                            color="primary"
                                            onClick={submitSearch}
                                        ><SearchIcon /></Button>
                                    </InputAdornment>
                                )
                            }} />
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <Typography
                                className={classes.typographyPopover}
                            >Eingabe zu kurz.</Typography>
                        </Popover>
                    </FormControl>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item sm={12}>
                            {searchInput.length > 2 ? <SuggestionDropdown
                                basePath="/"
                                resource="search"
                                rowClick={(id, basePath, record) => `/descriptors/${getLinkTarget(record)}/show`}
                                query={searchInput} /> : ""}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
};