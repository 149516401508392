import React from 'react';
import PropTypes from 'prop-types'
import {
    useQueryWithStore, 
    useLocale,
    useTranslate
} from 'react-admin';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import LoadingCircle from '../TabbedHeadingView/MeshTreeView/LoadingCircle'
import ConceptTab from "../TabbedHeadingView/ConceptTab";
import SubHeadingTab from "../TabbedHeadingView/SubHeadingTab";
import InformationTab from "../TabbedHeadingView/InformationTab";

import Grid from '@material-ui/core/Grid';
import { TabList } from '@material-ui/lab';


const TreeInformation = (props) => {

    //
    // Loads Children of Neighbors of Self on Click
    //
    const [value, setValue] = React.useState("1");

    const locale = useLocale();
    const translate = useTranslate();


    const currentID = `["${props.currentID}","${locale}"]`

    const { data, loading, error } = useQueryWithStore({
        type: 'getOne',
        resource: 'descriptors',
        payload: { id: currentID }
    });

    let record = (data || "");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    if (loading) return <LoadingCircle />;
    if (error) return null;
    if (!data) return null;
    return (
        <React.Fragment>
            <Grid item xl={12}
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                style={{ padding: "1rem", paddingTop:"0rem" }}>
                <InformationTab record={record} basePath={`/tree`} urlState={props.urlState} resource='descriptors' />
                        <hr/>
                <TabContext value={value}>
                    <TabList value={value} onChange={handleChange} aria-label="simple tabs example" s>
                        <Tab label={translate('resources.concepts.name')} value="1" />
                        <Tab label={translate('resources.qualifiers.name')} value="2" />
                    </TabList>
                    <TabPanel value="1"><ConceptTab record={record} basePath={`/tree`} resource='descriptors' /></TabPanel>
                    <TabPanel value="2"><SubHeadingTab record={record} basePath={`/tree`} resource='descriptors' path="qualifiers" /></TabPanel>

                </TabContext>
            </Grid>
        </React.Fragment>
    )
};

TreeInformation.propTypes = {
    currentID: PropTypes.string,
    urlState: PropTypes.string
}

export default TreeInformation;